import React from 'react';
import ReactDOM from 'react-dom';
import './css/grid.css';
import './css/index.css';
import './css/queries.css';

import computer from './css/img/computer.svg';
import pen from './css/img/pen.svg';
import network from './css/img/network.svg';
import facebook from './css/img/facebook-circular-logo.svg';
import twitter_logo_button from './css/img/twitter-logo-button.svg';
import linkedin_button from './css/img/linkedin-button.svg';

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';

import { Link, DirectLink, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'

import ReallySmoothScroll from 'really-smooth-scroll';
ReallySmoothScroll.shim();

library.add(faCheck)
library.add(faChevronRight)

class App extends React.Component {


  scrollToServices() {
    scroller.scrollTo('services', {
      duration: 800,
      delay: 0,
      smooth: 'easeInOutQuart'
    })
  }

  scrollToMethodology() {
    scroller.scrollTo('methodology', {
      duration: 800,
      delay: 0,
      smooth: 'easeInOutQuart'
    })
  }

  scrollToAboutus() {
    scroller.scrollTo('aboutus', {
      duration: 800,
      delay: 0,
      smooth: 'easeInOutQuart'
    })
  }


    scrollToContact() {
      scroller.scrollTo('contact', {
        duration: 800,
        delay: 0,
        smooth: 'easeInOutQuart'
      })
    }




  render() {
    return (
      <body>
        <div class="body-img">
          <nav>
              <div class="row ">
                  <ul class="main-nav">
                      <li><a class="a-nav" onClick={() => this.scrollToServices()}>SERVICES</a></li>
                      <li><a class="a-nav" onClick={() => this.scrollToMethodology()}>METHODOLOGY</a></li>
                      <li><a class="a-nav" onClick={() => this.scrollToAboutus()}>ABOUT US</a></li>
                      <li><a class="a-nav" onClick={() => this.scrollToContact()}>CONTACT</a></li>
                  </ul>
              </div>
          </nav>
          <header >
            <div class="background-img">
              <div class="text-box">
                 <h1>WE ARE PRETCOG</h1>
              </div>
              <div class="text-box-2">
                  We sit in the middle of the enterprise and cutting edge technology, and we look through those things that have become possible and interesting, then we understand their mechanics.
              </div>
              <a class="btn btn-full" href="#aboutus">About Us</a>
            </div>
          </header>
        </div>

        <section class="section-vr-img">
          <div class="vr-man">
          </div>
        </section>


        <section class="section-services" id="services">

            <div class="row">
                <h2>Services</h2>
                <p class="long-copy">
                    Technology has been dramatically altering the way businesses and markets operate. Devising tactics and maneuvers on top of new technologies and business models tied to them is the key to persistently successful strategies. We are aware of the time investment and complexity pressures of such endeavor on executives.
                </p>

            </div>


            <div class="row">
                <div class="col span-1-of-8 box">
                </div>
                <div class="col span-1-of-3 box-2">
                    <br/><br/><br/>
                    <h3>Technology Strategy Consulting</h3>
                    <p>
                      We help executives devise ways to infuse cutting edge technology into existing businesses We navigate you to:
                    </p>
                </div>
                <div class="col span-1-of-3 box">
                    <br/><br/>
                    <p><FontAwesomeIcon icon="check" color="#23A095"/>&nbsp;&nbsp;Identify potential infusion points and  possible &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;beneficial scenarios</p>
                    <p><br/><FontAwesomeIcon icon="check" color="#23A095"/>&nbsp;&nbsp;Review and rethink your business strategies</p>
                    <p><br/><FontAwesomeIcon icon="check" color="#23A095"/>&nbsp;&nbsp;Tap on new business opportunities</p>
                    <p><br/><FontAwesomeIcon icon="check" color="#23A095"/>&nbsp;&nbsp;Adopt new business models</p>
                </div>
                <div class="col span-1-of-5 box">
                </div>
            </div>

            <div class="row">
                <div class="col span-1-of-8 box">
                </div>
                <div class="col span-1-of-3 box-2">
                    <br/>
                    <h3>Subscription Services</h3>
                    <p>
                      We provide executives with to the point business technology resources in a rapid changing environmentWe prevent information overload by:
                    </p>
                </div>
                <div class="col span-1-of-3 box">
                    <p><FontAwesomeIcon icon="check" color="#23A095"/>&nbsp;&nbsp;Technology briefings</p>
                    <p><br/><FontAwesomeIcon icon="check" color="#23A095"/>&nbsp;&nbsp;On the spot strategic commentary</p>
                    <p><br/><FontAwesomeIcon icon="check" color="#23A095"/>&nbsp;&nbsp;One on one sessions</p>
                    <p><br/><FontAwesomeIcon icon="check" color="#23A095"/>&nbsp;&nbsp;Ad-hoc research reports</p>
                    <p><br/><FontAwesomeIcon icon="check" color="#23A095"/>&nbsp;&nbsp;Off the shelf reports</p>
                </div>
                <div class="col span-1-of-5 box">
                </div>
            </div>

            <div class="row">
                <div class="col span-1-of-8 box">
                </div>
                <div class="col span-1-of-3 box-2">
                    <h3>On demand technology</h3>
                    <p>
                      We help you comprehend the technology we are advising by:
                    </p>
                </div>
                <div class="col span-1-of-3 box">
                    <p><FontAwesomeIcon icon="check" color="#23A095"/>&nbsp;&nbsp;Demo</p>
                    <p><br/><FontAwesomeIcon icon="check" color="#23A095"/>&nbsp;&nbsp;Technical experts on call</p>
                </div>
                <div class="col span-1-of-8 box">
                </div>
            </div>
        </section>

        <section class="section-drone-img">
          <div class="drone">
          </div>
        </section>

        <section class="section-methodology" id="methodology">

          <div class="row">
              <h2>METHODOLOGY</h2>
          </div>

          <div class="row">
            <div class="col span-2-of-7 box-3">
                <img src={computer} height="120" width="120" alt="computer svg" class="methodology-icons-computer"/>
                <h3>We track technology landscape daily to stay on top of:</h3>
                <p><br/><FontAwesomeIcon icon="chevron-right" color="#23A095"/>&nbsp;&nbsp; Emerging technologies</p>
                <p><br/><FontAwesomeIcon icon="chevron-right" color="#23A095"/>&nbsp;&nbsp; Technology use cases</p>
                <p><br/><FontAwesomeIcon icon="chevron-right" color="#23A095"/>&nbsp;&nbsp; Competitive strategies</p>
                <p><br/><FontAwesomeIcon icon="chevron-right" color="#23A095"/>&nbsp;&nbsp; Business models</p>
                <p><br/><FontAwesomeIcon icon="chevron-right" color="#23A095"/>&nbsp;&nbsp; New policies and regulations</p>

            </div>

            <div class="col span-2-of-7 box-3">
                <img src={pen} height="120" width="120" alt="pen svg" class="methodology-icons-pen" />
                <h3>We have a hands-on style, we delve into technology and raw information to:</h3>
                <p><br/><FontAwesomeIcon icon="chevron-right" color="#23A095"/>&nbsp;&nbsp; Conduct deep analysis</p>
                <p><br/><FontAwesomeIcon icon="chevron-right" color="#23A095"/>&nbsp;&nbsp; Practice technologies we advise &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;on</p>
                <p><br/><FontAwesomeIcon icon="chevron-right" color="#23A095"/>&nbsp;&nbsp; Harness benefits of technology</p>

            </div>

            <div class="col span-2-of-7 box-3">
                <img src={network} height="120" width="120" alt="network svg" class="methodology-icons-network"/>
                <h3>We use our networks to navigate uncharted territories by:</h3>
                <p><br/><FontAwesomeIcon icon="chevron-right" color="#23A095"/>&nbsp;&nbsp; Learning from technology experts</p>
                <p><br/><FontAwesomeIcon icon="chevron-right" color="#23A095"/>&nbsp;&nbsp; Expanding our capabilities on demand</p>
                <p><br/><FontAwesomeIcon icon="chevron-right" color="#23A095"/>&nbsp;&nbsp; Sharing our network</p>

            </div>
          </div>
        </section>

        <section class="section-aboutus" id="aboutus">
          <div class="background-img-aboutus">
            <div class="circle-aboutus">
                <div class="text-box-aboutus">
                  ABOUT US
                </div>
            </div>
            <div class="col span-2-of-5"></div>
            <p class="col span-1-of-2 text-aboutus">
              <br/><br/>
              We sit in the middle of the enterprise and cutting edge technology, and we look through those things that have become possible and interesting, then we understand their mechanics.<br/><br/>
              A key piece of what we do is to find or develop use cases of technologies in real life business scenarios and create unique strategies.<br/><br/>
              The mind-blowing pace of technology induces lots of unknowns to decision processes. Leaders are now in a position where they have to know what is possible and how firms can utilize it. We are collaborating with deci- sion makers and helping them to identify technology<br/><br/>
              We are strategy and technology practitioners. We do what we do with passion. We envision a sustainable growth for our society. We help decision makers to shift companies to a more efficient and responsible mode of doing business.
            </p>
          </div>

        </section>

        <section class="section-contact" id="contact">
          <div class="col span-1-of-2 contact">
            <p class="contact-text">CONTACT</p>

              <ul class="social-media">
                  <li><a href="https://www.facebook.com/pretcog" target="_blank"><img src={facebook} height="50" width="50" alt="network svg"/></a></li>
                  <li><a href="https://twitter.com/pretcog" target="_blank"><img src={twitter_logo_button} height="50" width="50" alt="network svg"/></a></li>
                  <li><a href="https://www.linkedin.com/company/pretcog-consulting/" target="_blank"><img src={linkedin_button} height="50" width="50" alt="network svg"/></a></li>
              </ul>

           </div>
          <div class="col span-1-of-1 hiring">
            <p class="hiring-text">
              We are hiring! If you like what we do and you have experience in/student at:<br/><br/>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<FontAwesomeIcon icon="chevron-right" color="#23A095"/>&nbsp;&nbsp;&nbsp;AI, VR-AR-MR, Blockchain, Drones<br/><br/>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<FontAwesomeIcon icon="chevron-right" color="#23A095"/>&nbsp;&nbsp;&nbsp;Anthropology, Economics, Engineering, Sociology<br/><br/>
              Please send us your LinkedIn profile
            </p>
          </div>

          <div class="col span-1-of-1 black-section"> <p class="black-section-text">  You may go ahead and use our content, we believe in sharing (of course a due mention is expected)</p></div>
        </section>

      </body>
    );
  }
}

// ========================================


ReactDOM.render(
  <App />,
  document.getElementById('root')
);
